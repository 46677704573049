/* eslint-disable max-len */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { getCustomFieldOptions } from 'pages/tickets/api';
import { fetchAlerts } from './api';
import getDateRange from './utils';
/**
 * A redux thunk action creator for fetching alerts
 */
export const getAlerts = createAsyncThunk(
  'alerts/fetchAlerts',
  async (params, thunkAPI) => {
    try {
      const response = await fetchAlerts(params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for fetching the site options for site filter
 */
export const fetchSiteOptions = createAsyncThunk(
  'alerts/fetchSiteOptions',
  async (thunkAPI) => {
    try {
      const response = await getCustomFieldOptions('property_data');
      return response.length ? response : [];
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

const PAGE_SIZE = 20;
const { startDate, endDate } = getDateRange(30);

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    siteOptions: [],
    pages: {},
    data: [],
    lastPage: false,
    isAlertsLoading: false,
    lastEvaluatedKey: {},
    currentPage: 1,
    alertFilters: {
      alertType: '',
      siteFilter: [],
      status: [],
      categoryFilter: '',
      search: '',
      sortBy: {
        created_at: 'desc'
      },
      startDate,
      endDate
    }
  },
  reducers: {
    setSiteOptions: (state, action) => {
      state.siteOptions = action.payload;
    },
    setAlertsData: (state, action) => {
      const { page, data } = action.payload;
      state.pages[page] = data;
      if (data.length < PAGE_SIZE) {
        state.lastPage = true;
      }
    },
    resetAlerts: (state) => {
      state.pages = {};
      state.data = [];
      state.lastPage = false;
      state.lastEvaluatedKey = {};
      state.currentPage = 1;
    },
    resetFilters: (state) => {
      state.alertFilters = {
        alertType: '',
        siteFilter: [],
        status: [],
        categoryFilter: '',
        search: '',
        sortBy: {
          created_at: 'desc'
        },
        startDate,
        endDate
      };
    },
    setAlertFilters(state, action) {
      state.alertFilters = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setAlertsLoading: (state, action) => {
      state.isAlertsLoading = action.payload;
    },
    setLastEvaluatedKey: (state, action) => {
      state.lastEvaluatedKey[state.currentPage] = action.payload;
    }
  },
  extraReducers: {
    [getAlerts.pending]: (state) => {
      state.isAlertsLoading = true;
    },
    [getAlerts.fulfilled]: (state, action) => {
      const { data, last_evaluated_key } = action.payload.data;
      const currentPageData = state.pages[state.currentPage] || [];
      state.pages[state.currentPage] = [...currentPageData, ...data];
      state.isAlertsLoading = false;
      if (data.length < PAGE_SIZE) {
        state.lastPage = true;
      } else {
        state.lastEvaluatedKey[state.currentPage] = last_evaluated_key;
      }
    },
    [getAlerts.rejected]: (state) => {
      state.isAlertsLoading = false;
    },
    [fetchSiteOptions.fulfilled]: (state, action) => {
      state.siteOptions = action.payload;
    }
  }
});

export const selectAlerts = (state) => state.alerts;
export const selectAlertsData = (state, page) => state.alerts.pages[page];
export const selectAlertsLoading = (state) => state.alerts.isAlertsLoading;
export const selectAlertFilters = (state) => (state.alerts.alertFilters);
export const selectCurrentPage = (state) => (state.alerts.currentPage);
export const selectFiltersApplied = (state) => (state.alerts.filtersApplied);
export const selectLastEvaluatedKey = (state) => (state.alerts.lastEvaluatedKey);
export const selectSiteOptions = (state) => (state.alerts.siteOptions);
export const selectLastPage = (state) => (state.alerts.lastPage);

const { reducer, actions } = alertsSlice;
export const { setAlertsData, resetAlerts, resetFilters, setAlertFilters, setCurrentPage, setAlertsLoading, setLastEvaluatedKey, setSiteOptions } = actions;
export default reducer;
