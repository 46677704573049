import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  runTour: false,
  stepIndex: 0,
  pilotPageTourOpen: false,
  previousStepIndex: 0
};

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setRunTour: (state, action) => {
      state.runTour = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = Math.max(action.payload, 0);
    },
    setPreviousStepIndex: (state, action) => {
      state.previousStepIndex = action.payload;
    },
    setPilotPageTourOpen: (state, action) => {
      state.pilotPageTourOpen = action.payload;
    }
  }
});

export const selectRunTour = (state) => state.tour.runTour;
export const selectStepIndex = (state) => state.tour.stepIndex;
export const selectPreviousStepIndex = (state) => state.tour.previousStepIndex;
export const selectPilotPageTourOpen = (state) => state.tour.pilotPageTourOpen;

const { reducer, actions } = tourSlice;
export const { setRunTour, setStepIndex, setPreviousStepIndex, setPilotPageTourOpen } = actions;
export default reducer;
