import http from 'utils/http';
import store from 'app/store';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

const sendReportRequest = async (payload) => {
  try {
    const res = await http.post(`/smart-report/`, payload);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

const getSmartReport = async (taskId) => {
  let res;
  try {
    res = await http.get(`/celery/task/${taskId}`);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

export { sendReportRequest, getSmartReport };
