const getDateRange = (days) => {
  const now = new Date();
  const defaultEndDate = new Date(now);
  const formattedEndDate = defaultEndDate.toISOString();
  const defaultStartDate = new Date(now);
  defaultStartDate.setDate(now.getDate() - days);
  defaultStartDate.setHours(0, 0, 0, 0);
  const formattedStartDate = defaultStartDate.toISOString();
  return { endDate: formattedEndDate, startDate: formattedStartDate };
};

export default getDateRange;
