import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { ReactComponent as DangerIcon } from '../assets/danger_circle.svg';

const NotFoundPage = ({ theme }) => (
  <Box
    backgroundColor={theme.palette.background.secondary}
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    textAlign="center"
    width="100%"
    height="100vh"
  >
    <DangerIcon
      height="54px"
      width="54px"
      fill="none"
      stroke="white"
      style={{ marginBottom: '20px' }}
    />
    <Typography
      variant="h2"
      style={{
        marginBottom: '32px'
      }}
      maxWidth="78.93%"
    >
      404: Page Not Found
    </Typography>
    <Typography
      variant="title1Semibold"
      color={theme.palette.text.tooltipText2}
      maxWidth="78.93%"
    >
      The page you are looking for does not exist. Please check the URL or go back to the homepage.
    </Typography>
  </Box>
);

NotFoundPage.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        secondary: PropTypes.string.isRequired
      }).isRequired,
      text: PropTypes.shape({
        tooltipText2: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

export default NotFoundPage;
