import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { closeSnackbar, enqueueSnackbar } from 'snackbar/snackbarSlice';
import { sendReportRequest, getSmartReport } from './api';

export const requestReport = createAsyncThunk(
  'smartReport/export',
  async (params, thunkAPI) => {
    try {
      const res = await sendReportRequest(params);
      const taskId = res?.data?.task_id;
      if (taskId) {
        thunkAPI.dispatch(pollTaskStatus(taskId));
      }
      return taskId;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// polls Task status every 2 second
export const pollTaskStatus = createAsyncThunk(
  'smartReport/pollStatus',
  async (taskId, { dispatch }) => {
    const snackbarKey = new Date().getTime() + Math.random();
    dispatch(enqueueSnackbar({
      message: 'Exporting report...',
      isClearable: true,
      variant: 'default',
      key: snackbarKey,
      options: {
        autoHideDuration: null
      }
    }));
    const poll = async () => {
      try {
        const taskResult = await getSmartReport(taskId);
        const taskStatus = taskResult?.data?.status?.toUpperCase();
        if (taskStatus === 'SUCCESS') {
          dispatch(setDownloadUrl(taskResult?.data?.s3_url));
          dispatch(closeSnackbar(snackbarKey));
        } else if (taskStatus !== 'SUCCESS' && taskStatus !== 'FAILED') {
          setTimeout(poll, 2000);
        } else if (taskStatus === 'FAILED') {
          dispatch(closeSnackbar(snackbarKey));
          dispatch(enqueueSnackbar({
            message: 'Download failed',
            isClearable: true,
            variant: 'error',
            key: new Date().getTime() + Math.random()
          }));
        }
        dispatch(setTaskStatus(taskResult?.data?.status));
      } catch (error) {
        dispatch(enqueueSnackbar({
          message: 'Download failed',
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        }));
      }
    };

    await poll();
  }
);

// New function to handle popup blocked scenario
export const handlePopupBlocked = (pdfUrl) => (dispatch) => {
  dispatch(setPopupBlocked(true));
  const snackbarKey = new Date().getTime() + Math.random();
  dispatch(enqueueSnackbar({
    message: 'Your report is ready. Please allow popups on your browser to seamlessly download future reports.',
    actions: {
      'Download Report': () => window.open(pdfUrl, '_blank', 'noopener,noreferrer')
    },
    isClearable: true,
    variant: 'success',
    key: snackbarKey,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right'
    }
  }));
};

const smartReportSlice = createSlice({
  name: 'smartReport',
  initialState: {
    taskStatus: '',
    downloadUrl: '', // s3 url for pdf
    pollingTaskId: '',
    isLoading: false,
    popupBlocked: false
  },
  reducers: {
    setTaskStatus: (state, action) => {
      state.taskStatus = action.payload;
    },
    setDownloadUrl: (state, action) => {
      state.downloadUrl = action.payload;
    },
    setPopupBlocked: (state, action) => {
      state.popupBlocked = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestReport.pending, (state) => {
        state.taskStatus = 'PENDING';
        state.isLoading = true;
      })
      .addCase(requestReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pollingTaskId = action.payload?.data?.task_id;
      });
  }
});

export const selectTaskStatus = (state) => (state.smartReport.taskStatus);
export const selectIsLoading = (state) => (state.smartReport.isLoading);
export const selectPdfDownloadUrl = (state) => (state.smartReport.downloadUrl);
export const selectPollingTaskId = (state) => (state.smartReport.pollingTaskId);
export const { setTaskStatus, setDownloadUrl, setPopupBlocked } = smartReportSlice.actions;
export default smartReportSlice.reducer;
