/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { fetchRobotLogs, getRobotHealth, getMissionInstances } from 'pages/robotpage/api';

// Extract status info of an event
const getEventStatus = (event) => {
  if (event.level === '2' && event.compounding) {
    return 'info';
  }
  if (event.level === '2' && !event.compounding) {
    return 'success';
  }
  if (event.level === '4') {
    return 'warning';
  }
  if (event.level === '8') {
    return 'warning';
  }
  if (event.level === 'Heartbeat') {
    return 'info';
  }
  if (event.level === '16') {
    return 'error';
  }
  return 'info';
};

const modifyEventData = (eventArray) => eventArray?.map((event) => ({ ...event, status: getEventStatus(event) }));

/**
 * A redux thunk action creator for fetching events
 */
export const getEvents = createAsyncThunk(
  'robot/getEvents',
  async (params, thunkAPI) => {
    try {
      const response = await fetchRobotLogs(params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for fetching robot health data
 */
export const getRobotHealthDetails = createAsyncThunk(
  'robot/getRobotHealthDetails',
  async (params, thunkAPI) => {
    try {
      const response = await getRobotHealth(params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for fetching mission instances
 */
export const fetchMissionInstances = createAsyncThunk(
  'robot/fetchMissionInstances',
  async (params, thunkAPI) => {
    try {
      const response = await getMissionInstances(params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Robot slice
const robotSlice = createSlice({
  name: 'robot',
  initialState: {
    events: [],
    isEventsLoading: false,
    healthDetails: {},
    isRobotHealthDetailsLoading: false,
    missionInstances: [],
    isMissionInstancesLoading: false
  },
  reducers: {
    resetEvents(state) {
      state.events = [];
    },
    resetHealthDetails(state) {
      state.healthDetails = {};
    }
  },
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.isEventsLoading = true;
    },
    [getEvents.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.events = modifyEventData(data);
      state.isEventsLoading = false;
    },
    [getEvents.rejected]: (state) => {
      state.isEventsLoading = false;
    },
    [getRobotHealthDetails.pending]: (state) => {
      state.isRobotHealthDetailsLoading = true;
    },
    [getRobotHealthDetails.fulfilled]: (state, action) => {
      state.healthDetails = action.payload?.data;
      state.isRobotHealthDetailsLoading = false;
    },
    [getRobotHealthDetails.rejected]: (state) => {
      state.isRobotHealthDetailsLoading = false;
    },
    [fetchMissionInstances.pending]: (state) => {
      state.isMissionInstancesLoading = true;
    },
    [fetchMissionInstances.fulfilled]: (state, action) => {
      state.missionInstances = action.payload?.data;
      state.isMissionInstancesLoading = false;
    },
    [fetchMissionInstances.rejected]: (state) => {
      state.isMissionInstancesLoading = false;
    }
  }
});

export const selectEvents = (state) => (state.robot.events);
export const selectEventsLoading = (state) => (state.robot.isEventsLoading);
export const { resetEvents, resetHealthDetails } = robotSlice.actions;
export const selectRobotHealth = (state) => (state.robot.healthDetails);
export const selectRobotHealthLoading = (state) => (state.robot.isRobotHealthDetailsLoading);
export const selectMissionInstances = (state) => state.robot.missionInstances;
export const selectMissionInstancesLoading = (state) => state.robot.isMissionInstancesLoading;
const { reducer } = robotSlice;
export default reducer;
